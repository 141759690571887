/*
 * @Author: wangqs 
 * @description 测试模块
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-02-09 11:00:32
 */
<template>
  <div class="page-box">
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  async created () {
    let res = await this.$http.wxlogin({})
    if (res.code === 200 && res.msg.url) {
      window.location.replace(res.msg.url)
    } else {
      this.$toast('登录地址不存在，请联系管理员')
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
